<template>
  <div>
    <v-divider></v-divider>
    <v-footer
      color="#000"
      :min-height="$vuetify.breakpoint.name == 'xs' ? 'auto' : '80px'"
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex align-self-center xs12 px-md-4 px-lg-6>
              <v-layout
                wrap
                justify-center
                :text-left="$vuetify.breakpoint.name == 'xs' ? true : false"
              >
                <v-flex xs12 sm4 md4 lg4 align-self-center>
                  <span
                    style="
                      color: #d1d1d1;
                      font-family: poppinsregular;
                      font-size: 14px;
                    "
                    >Copyright ©2024 All Rights Reserved </span
                  >
                </v-flex>
                <v-flex xs12 sm4 md4 lg4 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center text-center>
                      <span
                        style="
                          color: #d1d1d1;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                        >Designed & Maintained By Leopard Tech Labs</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  py-sm-4
                  py-md-2
                  sm4
                  md4
                  lg4
                  py-2
                  align-self-end
                  text-right
                >
                  <span
                    style="
                      color: #d1d1d1;
                      font-family: poppinsregular;
                      font-size: 14px;
                    "
                    >Kerala Forest Department </span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userNavItems: [{ name: "Privacy Policy", route: "/PrivacyPolicy" }],
    };
  },
  computed: {
    menus() {
      return this.userNavItems;
    },
  },
  methods: {
    scrollToElement(id) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        });
      } else {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>