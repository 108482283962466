import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		isLoggedIn: !!localStorage.getItem("token"),
		userRole: localStorage.getItem('userRole'),
		userName: localStorage.getItem('userName'),
		userData: {},
		counterData: {},
		notification: 0,
		authentication: false,

		// appLoading: false
	},
	mutations: {
		loginUser(state, item) {
			localStorage.setItem("token", item)
			state.isLoggedIn = item
			// if (state.userRole == 'admin') router.push('/admin')
			// else if (state.userRole == 'official') router.push('/officers')
		},

		logoutUser(state) {
			
			axios({
				method: "GET",
				url: "/user/logout",
				headers: {
					"x-auth-token": localStorage.getItem("token")
				}
			}).then(response => {
				if (response.data) {
					state.userData = {}
					state.userRole = null
					state.isLoggedIn = false
					state.notification = 0
					localStorage.removeItem("token");
					localStorage.removeItem("userRole");
					localStorage.removeItem("userName");
					localStorage.removeItem("mainRole");
					localStorage.removeItem("activeSection");
					localStorage.removeItem("userType");

					if (window.location.pathname != '/') {
						// router.push('/')	
						router.push('/login')

					}
				}
			})

			

		},
		userData(state, item) {
			state.userData = item
		},
		counterData(state, item) {
			state.counterData = item
		},
		changeNotification(state, item) {
			state.notification = item
		},
		authValue(state, item) {
			state.authentication = item
		},

		userRole(state, item) {
			state.userRole = item
			localStorage.setItem("userRole", item);
		},
		mainRole(state, item) {
			state.mainRole = item
			localStorage.setItem("mainRole", item);
		},
		userName(state, item) {
			state.userName = item
			localStorage.setItem("userName", item);
		},
		sessionOut(state) {
			localStorage.removeItem("token");
			state.isLoggedIn = false
			router.push('/Login')

		}
		
	}

})