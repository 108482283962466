import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
// import Socket from "./Sockets/socket";

// import axios from 'axios'

Vue.use(Router)
let router = new Router({
	mode: 'history',
	routes: [

		{
			path: '/admin',
			props: true,
			component: () => import('./layouts/admin'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'adminDashboard',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: '',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},

				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'emergencyUser',
					name: 'emergencyUser',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EmergencyUser/adduser')
				},
				{
					path: 'reportCase',
					name: 'reportCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReportCase/reportCase')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCase/resolveCase')
				},
				{
					path: 'releaseCase',
					name: 'releaseCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReleaseCase/releaseCaseAdmin')
				},
				{
					path: 'myreport',
					name: 'myreport',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MyReports/myReport')
				},
				{
					path: 'closedcases',
					name: 'closedcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ClosedCases/closed')
				},
				{
					path: 'mapping',
					name: 'mapping',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Map/map')
				},
				{
					path: 'mergedlist',
					name: 'mergedlist',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MergedCases/merged')
				},
				{
					path: 'editresolveCase',
					name: 'editresolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EditResolve/resolveEditadmin')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/public')
						},
						{

							path: 'reports',
							name: 'reports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/reports')
						},
					],
				},
				{
					path: 'mergeCases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: "",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/MergeCases/mergeCases'),
							props: true,
						},
						{
							path: "mergeCaseSuggestion",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/MergeCases/mergeCaseSuggestion'),
							props: true,
						}
					]
				},
				{
					path: 'dynamicData',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: 'scenerios',
							name: 'scenerios',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Scenerios/scenerios')
						},

						{

							path: 'animals',
							name: 'animals',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Animals/Animals')
						},
						{

							path: 'animalActivities',
							name: 'animalActivities',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalActivities/animalActivities')
						},
						{

							path: 'animalAge',
							name: 'animalAge',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalAge/animalAge')
						},
						{

							path: 'animalStatus',
							name: 'animalStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalStatus/animalStatus')
						},
						{

							path: 'gender',
							name: 'gender',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Gender/gender')
						},

						{

							path: 'mitigationTypes',
							name: 'mitigationType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationTypes')
						},
						{

							path: 'mitigationMethods',
							name: 'mitigationMethod',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationMethods')
						},
						{

							path: 'releaseTypes',
							name: 'releaseType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ReleaseTypes/releaseTypes')
						},
						{

							path: 'landTypes',
							name: 'landType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/LandTypes/landTypes')
						},
						{

							path: 'breachType',
							name: 'breachType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BreachType/breachType')
						},
						{

							path: 'barrierType',
							name: 'barrierType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BarrierType/barrierType')
						},
						{

							path: 'conflictType',
							name: 'conflictType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ConflictType/conflictType')
						},
						{

							path: 'cropType',
							name: 'cropType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CropType/cropType')
						},
						{

							path: 'veterinaryRecommendation',
							name: 'veterinaryRecommendation',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/VeterinaryRecommendation/veterinaryRecommendation')
						},
						{

							path: 'closeStatus',
							name: 'closeStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CloseStatus/closeStatus')
						},
					],
				},
				{
					path: 'sarpa',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{

							path: '/Sarpa/dashboard',
							name: 'sarpadashboard',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/dashboard')
						},
						{

							path: '/topcommonsnakes',
							name: 'topcommonsnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topSnakes.vue')
						},
						{

							path: '/usermap',
							name: 'usermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/userMap.vue')
						},
						{

							path: '/rescuermap',
							name: 'rescuermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/rescuerMap.vue')
						},
						{

							path: '/hospitalMap',
							name: 'hospitalMap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/hospitalMap.vue')
						},
						{

							path: '/listedSnakes',
							name: 'listedSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/listedSnakes.vue')
						},
						{

							path: '/editSnake',
							name: 'editSnake',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/editSnake.vue')
						},
						{

							path: '/snakedetails',
							name: 'snakedetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakeDetails.vue')
						},
						{

							path: '/snakebiteDeath',
							name: 'snakebiteDeath',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakebiteDeath.vue')
						},
						{

							path: '/snakerescued',
							name: 'snakerescued',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/snakeRescuedmap.vue')
						},
						{

							path: '/topUsers',
							name: 'topUsers',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topUsers.vue')
						},
						{

							path: '/topUsersyear',
							name: 'topUsersyear',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/topUsersyear.vue')
						},
						{

							path: '/addSnakes',
							name: 'addSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Addsnakes/addSnakes.vue')
						},
						{

							path: '/usersList',
							name: 'usersList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/UserList/usersList.vue')
						},
						{

							path: '/edituser',
							name: 'edituser',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/UserList/editUser.vue')
						},
						{

							path: '/newrescuerslist',
							name: 'newrescuerslist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/newList.vue')
						},
						{

							path: '/newrescuerslistview',
							name: 'newrescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/viewDetails.vue')
						},
						{

							path: '/newrescuerslistedit',
							name: 'newrescuerslistedit',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/NewRescuers/editDetails.vue')
						},
						{

							path: '/allrescuerlist',
							name: 'allrescuerlist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuerList/alluserlist.vue')
						},
						{

							path: '/bannedrescuerlist',
							name: 'bannedrescuerlist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuerList/bannedList.vue')
						},
						{

							path: '/rescuerslistview',
							name: 'rescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuerList/viewDetails.vue')
						},
						{

							path: '/pendingoperations',
							name: 'pendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/PendingOperations/pending.vue')
						},
						{

							path: '/approveoperations',
							name: 'approveoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApproveOperations/list.vue')
						},
						{

							path: '/viewpendingoperations',
							name: 'viewpendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/PendingOperations/viewPending.vue')
						},
						{

							path: '/assignedoperations',
							name: 'assignedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AssignedOperations/list.vue')
						},
						{

							path: '/assignedoperationsview',
							name: 'assignedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AssignedOperations/view.vue')
						},
						{

							path: '/rescuedoperations',
							name: 'rescuedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuedOperations/list.vue')
						},
						{

							path: '/rescuedoperationsview',
							name: 'rescuedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/RescuedOperations/view.vue')
						},
						{

							path: '/releasedoperations',
							name: 'releasedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ReleasedOperations/list.vue')
						},
						{

							path: '/releasedoperationsview',
							name: 'releasedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ReleasedOperations/view.vue')
						},
						{

							path: '/approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: '/approvedoperations',
							name: 'approvedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/list.vue')
						},
						{

							path: '/approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: '/deleteoperations',
							name: 'deleteoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DeleteOperations/list.vue')
						},
						{

							path: '/deleteoperationsview',
							name: 'deleteoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/DeleteOperations/view.vue')
						},
						{

							path: '/traininglist',
							name: 'traininglist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/TrainingList/list.vue')
						},
						{

							path: '/traininglistview',
							name: 'traininglistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/TrainingList/view.vue')
						},
						{

							path: '/snakereports',
							name: 'snakereports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeReports/list.vue')
						},
						{

							path: '/snakereportsview',
							name: 'snakereportsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/SnakeReports/view.vue')
						},
						{

							path: '/addsnakeexperts',
							name: 'addsnakeexperts',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AddSnakeExperts/add.vue')
						},
						{

							path: '/addhospital',
							name: 'addhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/AddHospital/add.vue')
						},
						{

							path: '/Viewhospital',
							name: 'Viewhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ViewHospital/list.vue')
						},
						{

							path: '/edithospital',
							name: 'edithospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ViewHospital/edit.vue')
						},
						{

							path: '/identificationhelp',
							name: 'identificationhelp',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Identification/help.vue')
						},
						
						{

							path: '/excelreport',
							name: 'excelreport',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Excel/excelReport.vue')
						},
						{

							path: '/identificationhelpview',
							name: 'identificationhelpview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Identification/helpview.vue')
						},
						{

							path: '/approvehospital',
							name: 'approvehospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/ApproveHospital/list.vue')
						},
						{

							path: '/reportGeneration',
							name: 'reportGeneration',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/FilterData/filterData.vue')
						},
						

						
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Cases/caseDetails')
						},
					],
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/analytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analyticspage',
					name: 'analyticspage',
					props: true,
					component: () => import('./views/Analytics/newAnalytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'commonanalyticspage',
					name: 'commonanalyticspage',
					props: true,
					component: () => import('./views/Analytics/commonDash'),
					meta: {
						requiresAuth: true,
					}
				},
			]
		},

		{
			path: '/emergencyuser',
			props: true,
			component: () => import('./layouts/emergencyuser'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'adminDashboard',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: '',
					name: 'adminDashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboardAdmin')
				},

				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},

				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'emergencyUser',
					name: 'emergencyUser',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EmergencyUser/adduser')
				},
				{
					path: 'reportCase',
					name: 'reportCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReportCase/reportCase')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCase/resolveCase')
				},
				{
					path: 'releaseCase',
					name: 'releaseCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ReleaseCase/releaseCase')
				},
				{
					path: 'myreport',
					name: 'myreport',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/MyReports/myReport')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/public')
						},
						{

							path: 'reports',
							name: 'reports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/Public/reports')
						},
					],
				},
				{
					path: 'dynamicData',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: 'scenerios',
							name: 'scenerios',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Scenerios/scenerios')
						},

						{

							path: 'animals',
							name: 'animals',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Animals/Animals')
						},
						{

							path: 'animalActivities',
							name: 'animalActivities',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalActivities/animalActivities')
						},
						{

							path: 'animalAge',
							name: 'animalAge',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalAge/animalAge')
						},
						{

							path: 'animalStatus',
							name: 'animalStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/AnimalStatus/animalStatus')
						},
						{

							path: 'gender',
							name: 'gender',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Gender/gender')
						},

						{

							path: 'mitigationTypes',
							name: 'mitigationType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationTypes')
						},
						{

							path: 'mitigationMethods',
							name: 'mitigationMethod',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/Mitigations/mitigationMethods')
						},
						{

							path: 'releaseTypes',
							name: 'releaseType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ReleaseTypes/releaseTypes')
						},
						{

							path: 'landTypes',
							name: 'landType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/LandTypes/landTypes')
						},
						{

							path: 'breachType',
							name: 'breachType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BreachType/breachType')
						},
						{

							path: 'barrierType',
							name: 'barrierType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/BarrierType/barrierType')
						},
						{

							path: 'conflictType',
							name: 'conflictType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/ConflictType/conflictType')
						},
						{

							path: 'cropType',
							name: 'cropType',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CropType/cropType')
						},
						{

							path: 'veterinaryRecommendation',
							name: 'veterinaryRecommendation',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/VeterinaryRecommendation/veterinaryRecommendation')
						},
						{

							path: 'closeStatus',
							name: 'closeStatus',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Admin/DynamicData/CloseStatus/closeStatus')
						},
					],
				},
				{
					path: 'sarpa',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [

						{

							path: '/Sarpa/dashboard',
							name: 'sarpadashboard',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/dashboard')
						},

						
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Cases/caseDetails')
						},
					],
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/analytics'),
					meta: {
						requiresAuth: true,
					}
				},
			]
		},

		{
			path: '/officers',
			props: true,
			component: () => import('./layouts/officers'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'dashboard',
					name: 'dashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboard')
				},
				{
					path: 'closedcases',
					name: 'closedcases',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ClosedCases/closed')
				},
				{
					path: '',
					name: 'dashboard',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Dashboard/dashboard')
				},
				{
					path: 'profile',
					name: 'profile',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Profile/profile')
				},
				{
					path: 'resolveCase',
					name: 'resolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ResolveCaseOfficer/ResolveCaseOfficer')
				},
				{
					path: 'editresolveCase',
					name: 'editresolveCase',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/EditResolve/resolveEdit')
				},
				{

					path: 'reportGenerationofficers',
					name: 'reportGenerationofficers',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/FilterData/filterDataofficer.vue')
				},
				{
					path: 'conflictReports',
					name: 'conflictReports',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/ConflictReports/conflictReports')
				},
				{
					path: 'officers',
					name: 'officersSection',
					meta: {
						requiresAuth: true,
					},
					component: () => import('./views/Officers/Officers/officers')
				},
				{
					path: 'public',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: '',
							name: 'public',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Public/public')
						},
						// {

						// 	path: 'reports',
						// 	name: 'reports',
						// 	meta: {
						// 		requiresAuth: true,
						// 	},
						// 	component: () => import('./views/Admin/Public/reports')
						// },
					],
				},
				{
					path: 'cases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: 'caseDetails',
							name: 'caseDetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Cases/caseDetails')
						},
					],
				},
				{
					path: 'mergeCases',
					meta: {
						requiresAuth: true,
					},
					component: {
						render(c) { return c('router-view') }
					},
					children: [
						{
							path: "",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/MergeCases/mergeCases'),
							props: true,
						},
						{
							path: "mergeCaseSuggestion",
							name: "MergeCase",
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/MergeCases/mergeCaseSuggestion'),
							props: true,
						}
					]
				},
				{
					path: 'notifications',
					name: 'notification',
					props: true,
					component: () => import('./views/Notifications/notifications'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'analytics',
					name: 'Analytics',
					props: true,
					component: () => import('./views/Analytics/newAnalytics'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'reportcase',
					name: 'reportcase',
					props: true,
					component: () => import('./views/ReportCase/reportCase'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'resolvecase',
					name: 'resolvecase',
					props: true,
					component: () => import('./views/ResolveCase/resolveCase'),
					meta: {
						requiresAuth: true,
					}
				},
				{
					path: 'releasecase',
					name: 'releasecase',
					props: true,
					component: () => import('./views/ReleaseCase/releaseCase'),
					meta: {
						requiresAuth: true,
					}
				},
						{

							path: 'sarpadashboard',
							name: 'sarpadashboard',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/dashboard')
						},
						{

							path: 'topcommonsnakes',
							name: 'topcommonsnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/topSnakes.vue')
						},
						{

							path: 'usermap',
							name: 'usermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/userMap.vue')
						},
						{

							path: 'rescuermap',
							name: 'rescuermap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/rescuerMap.vue')
						},
						{

							path: 'hospitalMap',
							name: 'hospitalMap',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/hospitalMap.vue')
						},
						{

							path: 'listedSnakes',
							name: 'listedSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/listedSnakes.vue')
						},
						{

							path: 'editSnake',
							name: 'editSnake',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Sarpa/Dashboard/editSnake.vue')
						},
						{

							path: 'snakedetails',
							name: 'snakedetails',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/snakeDetails.vue')
						},
						{

							path: 'snakebiteDeath',
							name: 'snakebiteDeath',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/snakebiteDeath.vue')
						},
						{

							path: 'snakerescued',
							name: 'snakerescued',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/snakeRescuedmap.vue')
						},
						{

							path: 'topUsers',
							name: 'topUsers',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/topUsers.vue')
						},
						{

							path: 'topUsersyear',
							name: 'topUsersyear',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Dashboard/topUsersyear.vue')
						},
						{

							path: 'addSnakes',
							name: 'addSnakes',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Addsnakes/addSnakes.vue')
						},
						{

							path: 'usersList',
							name: 'usersList',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/UserList/usersList.vue')
						},
						{

							path: 'edituser',
							name: 'edituser',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/UserList/editUser.vue')
						},
						{

							path: 'newrescuerslist',
							name: 'newrescuerslist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/NewRescuers/newList.vue')
						},
						{

							path: 'newrescuerslistview',
							name: 'newrescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/NewRescuers/viewDetails.vue')
						},
						{

							path: 'newrescuerslistedit',
							name: 'newrescuerslistedit',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/NewRescuers/editDetails.vue')
						},
						{

							path: 'allrescuerlist',
							name: 'allrescuerlist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/RescuerList/alluserlist.vue')
						},
						{

							path: 'rescuerslistview',
							name: 'rescuerslistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/RescuerList/viewDetails.vue')
						},
						{

							path: 'pendingoperations',
							name: 'pendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/PendingOperations/pending.vue')
						},
						{

							path: 'viewpendingoperations',
							name: 'viewpendingoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/PendingOperations/viewPending.vue')
						},
						{

							path: 'assignedoperations',
							name: 'assignedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/AssignedOperations/list.vue')
						},
						{

							path: 'assignedoperationsview',
							name: 'assignedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/AssignedOperations/view.vue')
						},
						{

							path: 'rescuedoperations',
							name: 'rescuedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/RescuedOperations/list.vue')
						},
						{

							path: 'rescuedoperationsview',
							name: 'rescuedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/RescuedOperations/view.vue')
						},
						{

							path: 'releasedoperations',
							name: 'releasedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ReleasedOperations/list.vue')
						},
						{

							path: 'releasedoperationsview',
							name: 'releasedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ReleasedOperations/view.vue')
						},
						{

							path: 'approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: 'approvedoperations',
							name: 'approvedoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ApprovedOperations/list.vue')
						},
						{

							path: 'approvedoperationsview',
							name: 'approvedoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ApprovedOperations/view.vue')
						},
						{

							path: 'deleteoperations',
							name: 'deleteoperations',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/DeleteOperations/list.vue')
						},
						{

							path: 'deleteoperationsview',
							name: 'deleteoperationsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/DeleteOperations/view.vue')
						},
						{

							path: 'traininglist',
							name: 'traininglist',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/TrainingList/list.vue')
						},
						{

							path: 'traininglistview',
							name: 'traininglistview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/TrainingList/view.vue')
						},
						{

							path: 'snakereports',
							name: 'snakereports',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/SnakeReports/list.vue')
						},
						{

							path: 'snakereportsview',
							name: 'snakereportsview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/SnakeReports/view.vue')
						},
						{

							path: 'addsnakeexperts',
							name: 'addsnakeexperts',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/AddSnakeExperts/add.vue')
						},
						{

							path: 'addhospital',
							name: 'addhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/AddHospital/add.vue')
						},
						{

							path: 'Viewhospital',
							name: 'Viewhospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ViewHospital/list.vue')
						},
						{

							path: 'edithospital',
							name: 'edithospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ViewHospital/edit.vue')
						},
						{

							path: 'identificationhelp',
							name: 'identificationhelp',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Identification/help.vue')
						},
						
						{

							path: 'excelreport',
							name: 'excelreport',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Excel/excelReport.vue')
						},
						{

							path: 'identificationhelpview',
							name: 'identificationhelpview',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/Identification/helpview.vue')
						},
						{

							path: 'approvehospital',
							name: 'approvehospital',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/Officers/Sarpa/ApproveHospital/list.vue')
						},
						{

							path: '/reportGeneration',
							name: 'reportGeneration',
							meta: {
								requiresAuth: true,
							},
							component: () => import('./views/FilterData/filterData.vue')
						},
						

						
					],
		},
		// {
		// 	path: '/',
		// 	props: true,
		// 	component: () => import('./layouts/authentication'),
		// 	meta: {
		// 		requiresAuth: false,
		// 	},
		// 	children: [
		// 		{
		// 			path: 'login',
		// 			name: 'login',
		// 			meta: {
		// 				requiresAuth: false,
		// 			},
		// 			component: () => import('./layouts/authentication')
		// 		},
		// 	]
		// },
		{
			path: '/',
			props: true,
			component: () => import('./layouts/authenticationTest'),
			meta: {
				requiresAuth: false,
			},
			children: [
				{
					path: 'login',
					name: 'login',
					meta: {
						requiresAuth: false,
					},
					component: () => import('./layouts/authenticationTest')
				},
			]
		},
		{
			path: '/servererror',
			name: 'servererror',
			props: true,
			component: () => import('./components/Common/500'),
			meta: {
				requiresAuth: false,
			}
		},
		
		{
			path: '/PrivacyPolicy',
			name: 'PrivacyPolicy',
			props: true,
			component: () => import('./views/GeneralPages/PrivacyPolicy'),
			meta: {
				requiresAuth: false,
			}
		},
		// {
		// 	path: '/map',
		// 	name: 'map',
		// 	props: true,
		// 	component: () => import('./views/Officers/Cases/map'),
		// 	meta: {
		// 		requiresAuth: false,
		// 	}
		// },
		{
			path: '*',
			name: '404pagenotfound',
			props: true,
			component: () => import('./components/Common/404'),
			meta: {
				requiresAuth: false,
			}
		},
		{

			path: '/Support',
			name: 'Support',
			meta: {
				requiresAuth: false,
			},
			component: () => import('./views/Sarpa/SupportPage/supportPage.vue')
		},

	],
	scrollBehavior() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
	},
})

router.beforeEach((to, from, next) => {
	// 	if(!store.state.authentication)
	// 	{
	// Socket.authFunction()
	// 	}
	if (to.matched.some(route => route.meta.requiresAuth == true) && store.state.isLoggedIn == false) {
		console.log(from)
		next({ name: 'login', params: { lastPage: from } })
		return
	}
	// if (store.state.isLoggedIn) {
	// 	var url
	// 	if (store.state.userRole == 'official')
	// 		url = '/authenticate/official'
	// 	else
	// 		if (store.state.userRole == 'admin')
	// 			url = '/authenticate/admin'
	// 	axios({
	// 		method: 'POST',
	// 		url: url,
	// 		headers: {
	// 			'token': localStorage.getItem('token')
	// 		}
	// 	}).then(response => {
	// 		if (!response.data.status) {
	// 			store.commit("sessionOut", true)
	// 			return
	// 		}
	// 		// else {
	// 			// alert(response.data.msg)
	// 		// }
	// 	})
	// 		.catch(err => {
	// 			var msg = err;
	// 			console.log(msg)
	// 		});
	// }
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'admin' && store.state.activeSection == 'hwc') {
		next({ name: 'adminDashboard' })
		return
	}
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'admin' && store.state.activeSection == 'sarpa') {
		next({ name: 'sarpadashboard' })
		return
	}
	if ((to.name == 'login' || to.path == '/') && store.state.isLoggedIn == true && store.state.userRole == 'official') {
		next({ name: 'dashboard' })
		return
	}
	next()
})

export default router