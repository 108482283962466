//Mylib.js

// this (vue instance) is passed as that , so we
// can read and write data from and to it as we please :)
let dataFunction = (that) => {

  // that.$socket.on("fortest", function (data) {
  //   console.log("fortest on");
  //   console.log(JSON.stringify(data));
  // });
  that.$socket.emit("auth", {
    token: localStorage.getItem('token'),
    // from: "web",
  });
  that.sockets.subscribe("new_update", (response) => {
    if (response) {
      // responseData=response
      that.socketType = response.type
      that.socketData = response.data
    }
  });

};
let countFunction = (that) => {

  // that.$socket.on("fortest", function (data) {
  //   console.log("fortest on");
  //   console.log(JSON.stringify(data));
  // });
  that.$socket.emit("auth", {
    token: localStorage.getItem('token'),
    // from: "web",
  });
  that.sockets.subscribe("count_update", (response) => {
    if (response) {
      // responseData=response
      that.role = response.role
      that.newData = response.count
      if (that.role == "official") {
        that.assignAnalytics(that.newData)
        that.mergeValue = that.newData.mergeSuggestion;


      }
    }
  });

};
let notificationFunction = (that) => {

  // that.$socket.on("fortest", function (data) {
  //   console.log("fortest on");
  //   console.log(JSON.stringify(data));
  // });
  that.$socket.emit("auth", {
    token: localStorage.getItem('token'),
    // from: "web",
  });
  that.sockets.subscribe("notification_update", (response) => {
    if (response) {
      // responseData=response
      console.log("res", response)
      that.unreadNotification = response.unreadNotification
      that.notificationData = response.data
      that.notifications.unshift(response.data)
      that.$store.commit("changeNotification", response.unreadNotification);

    }
  });

};
let authFunction = (that) => {
  that.$socket.emit("auth", {
    token: localStorage.getItem('token'),
    // from: "web",
  });
  that.sockets.subscribe("auth", (response) => {
    if (response) {
      //  console.log(response, "response");
      that.response = response
      that.authentication = response.authentication
      that.$store.commit("authValue", response.authentication)
    }
  });

};
let logoutFunction = (that) => {
  that.$socket.emit("auth", {
    token: localStorage.getItem('token'),
    // from: "web",
  });
  that.sockets.subscribe("logout", (response) => {
    if (response) {
      //  console.log(response, "response");
      that.response = response 
      console.log("res", response)

    }
  });

};

var xport = {
  dataFunction: dataFunction,
  countFunction: countFunction,
  authFunction: authFunction,
  logoutFunction: logoutFunction,
  notificationFunction: notificationFunction
};

export default xport;
